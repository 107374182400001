import jQuery from 'jquery';

import Swiper, { Mousewheel, Navigation, Pagination } from 'swiper';
import 'swiper/scss';

export default {
  init() {
    // JavaScript to be fired on all pages

    var contador = 1;

    if(jQuery('body.admin-bar').length > 0) {
      jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() < jQuery('#wpadminbar').height()) {
          jQuery('header').css('top', '32px');
          jQuery('.title-page').css('top', '32px');
        } else {
          jQuery('header').css('top', '0px');
          jQuery('.title-page').css('top','0px');
        }
      });
    }
    
    jQuery('.button-menu-responsive').click(function(){
      if(contador == 1){
        jQuery('.nav-responsive').animate({
          right: '0',
        });
        contador = 0;
      } else {
        contador = 1;
        jQuery('.nav-responsive').animate({
          right: '-100%',
        });
      }
    });

    jQuery('.nav-responsive > .wrapper-nav > .cerrar').click(function(){
      if(contador == 1){
        jQuery('.nav-responsive').animate({
          right: '0',
        });
        contador = 0;
      } else {
        contador = 1;
        jQuery('.nav-responsive').animate({
          right: '-100%',
        });
      }
    });


    if(jQuery('.slides').length > 0) {
      new Swiper('.slides', {
        modules: [Navigation],
        direction: 'horizontal', 
        slidesPerView: 1,
        init: true, 
        loop: true,
        speed: 1000,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }

    if(jQuery('.otros-proyectos').length > 0) {
      new Swiper('.otros-proyectos', {
        modules: [Pagination],
        direction: 'horizontal', 
        slidesPerView: 3,
        spaceBetween: 2,
        init: true, 
        speed: 1000,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          992: {
            spaceBetween: 50,
          },
        },
      });
    }

  if(jQuery('.container-chevron').lenght > 0) {
    jQuery('.container-chevron').click(function() {
      jQuery([document.documentElement, document.body]).animate({
          scrollTop: jQuery('.informacion').offset().top - 126,
      }, 300);
    });
  }

  var swiper;
  swiper == 0;
  var init = false;

  function swiperMode() {
    let mobile = window.matchMedia('(min-width: 0px) and (max-width: 992px)');
    let desktop = window.matchMedia('(min-width: 992px)');

    // Enable (for mobile)
    if(mobile.matches) {
      swiper = new Swiper('.swiper', {
        slidesPerView: 2,
        spaceBetween: 15,
      });
    }

    // Disable (for desktop)
    else if(desktop.matches) {
      if (!init) {
          init = true;
          swiper = new Swiper('.swiper', {
            modules: [Mousewheel],
            direction: 'vertical', 
            slidesPerView: 'auto',
            freeMode: true, 
            mousewheel: true,
          });
      }
    }
  }

      /* On Load
  **************************************************************/
  window.addEventListener('load', function() {
    if(jQuery('.swiper').length > 0) {
      swiperMode();
    }
  });

  /* On Resize
  **************************************************************/
  window.addEventListener('resize', function() {
    swiperMode();
  });


  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
